import React from "react";
import styles from "./HomePage.module.scss";

const HomePage = () => {
  return (
    <>
 <p>Home page</p>
 </>
  );
};

export default HomePage;
