import React from "react";
import styles from "./Routes.module.scss";
import { Router, Redirect } from "@reach/router";
import HomePage from "../HomePage";
import Page1 from "../Page1";
import Page2 from "../Page2";
import Page3 from "../Page3";
import NotFound from "../NotFound";


const Routes = () => {
  return (
    <>
      <Router>
      <Redirect noThrow from= "/" to="homepage" />
      <HomePage path="homepage" />
      <Page1 path="page1" />
      <Page2 path="page2" />
      <Page3 path="page3" />
      <NotFound default />
    </Router>
    </>
  );
};

export default Routes;
