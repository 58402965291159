import React from "react";
import styles from "./NavBar.module.scss";
import { Link } from "@reach/router";

const NavBar = (props) => {

  const {links} = props;

  return (
    <>
      {links.map(link => (
        <Link to={link.linkPath}>{link.linkText}</Link>
      ))}
    </>
  );
};

export default NavBar;
