import React from "react";
import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <>
      <p>NotFound! Choose a new url</p>
    </>
  );
};

export default NotFound;
