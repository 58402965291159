import React from "react";
import styles from "./Page1.module.scss";

const Page1 = () => {
  return (
    <>
      <p>Page1</p>
    </>
  );
};

export default Page1;
