import React from "react";
import styles from "./Page3.module.scss";

const Page3 = () => {
  return (
    <>
      <p>Page3</p>
    </>
  );
};

export default Page3;
