import React, { useState } from 'react';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faDog } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import Button from './components/Button';
import SearchBar from './components/SearchBar'
import Router from "./containers/Routes"
import NavBar from "./containers/NavBar";


library.add(faCoffee, faCheckSquare, faDog);

const linkArray = [
  {
    linkText: "Home Page",
    linkPath: "homepage",
  },

  {
    linkText: "Page 1",
    linkPath: "page1",
  },

  {
    linkText: "Page 2",
    linkPath: "page2",
  },

  {
    linkText: "Page 3",
    linkPath: "page3",
  },

]

function App() {

//   const [currentNumber, updateNumber] = useState(100);

// const testFunc = (inputVal) => {
//   updateNumber(inputVal);
// };

  return (
  //  <>
  //  <h1>{currentNumber}</h1>
  //  <SearchBar handleInput={(inputVal) =>  updateNumber(parseInt(inputVal))} placeholderText={"Your number here"}/>
  //    <Button btnText="Increment" handleClick={() => updateNumber(currentNumber + 1)}/>
  //    <Button btnText="Decrement" handleClick={() => updateNumber(currentNumber - 1)}/>
  //    <Button btnText="x2" handleClick={() => updateNumber(currentNumber * 2)}/>
  //    <Button btnText="/2" handleClick={() => updateNumber(currentNumber / 2)}/>

  //  </>
      <>
      <NavBar links={linkArray}/>
      <Router />
      </>

  );
}

export default App;
